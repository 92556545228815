<template>
  <div class="single-company">
    <!-- <a
      v-if="oneCompany.url"
      :href="oneCompany.url ? oneCompany.url : false"
      class="company-link"
      target="_blank"
    >
      {{ oneCompany.company }}
    </a> -->

    <component
      :is="oneCompany.company_code ? 'a' : 'span'"
      :href="
        oneCompany.company_code
          ? 'https://startuptaiwan.org/startup/' + oneCompany.company_code
          : false
      "
      class="company-link"
      target="_blank"
    >
      {{ oneCompany.company }}
    </component>

    <div class="tags">
      <span class="one-tag" v-for="(tag, index) in tagsArr" :key="index">
        #{{ tag }}
      </span>
    </div>
  </div>
</template>

<script>
const moment = require('moment');
import _ from 'lodash';

export default {
  props: {
    oneCompany: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      searchText: ''
    };
  },

  methods: {},
  computed: {
    tagsArr() {
      let arr = [];
      if (this.oneCompany.type) {
        let temp = this.oneCompany.type.split(', ');
        arr.push(...temp);
      }
      if (this.oneCompany.apply) {
        let temp = this.oneCompany.apply.split(', ');
        arr.push(...temp);
      }
      if (this.oneCompany.tech) {
        let temp = this.oneCompany.tech.split(', ');
        arr.push(...temp);
      }
      return arr;
    }
  }
};
</script>

<style lang="scss" scoped></style>
