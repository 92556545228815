import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/bootstrap-vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
import moment from 'moment';
import VueMoment from 'vue-moment';
import '@fortawesome/fontawesome-free/css/all.css';
import Select2 from 'v-select2-component';
import ECharts from 'vue-echarts';
import VueScrollTo from 'vue-scrollto';
import wordcloud from 'vue-wordcloud-tooltip-fix';
import AnimatedNumber from 'animated-number-vue';
import VueMeta from 'vue-meta';
import Loading from 'vue-loading-overlay';
import InfiniteLoading from 'vue-infinite-loading';
import './plugins/validate/vee-validate';

// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';

// library.add(faSearch);

Vue.config.productionTip = false;
moment.locale('zh-tw');
Vue.use(VueMoment, { moment });
Vue.use(InfiniteLoading);
Vue.use(Loading, { loader: 'bars', color: '#00B1B2', height: 128, width: 128 });
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
Vue.component('DatePicker', DatePicker);
Vue.component('Select2', Select2);
Vue.component('v-chart', ECharts);
Vue.component('wordcloud', wordcloud);
Vue.component('AnimatedNumber', AnimatedNumber);
// Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
