<template>
  <div id="app">
    <HeaderUnicorn v-if="$route.name == 'Unicorn' && isDataLoaded" />
    <router-view />
    <Footer v-if="$route.name == 'Unicorn' && isDataLoaded" />
    <BottomMenuUnicorn v-if="$route.name == 'Unicorn' && isDataLoaded" />
    <CompanyModal
      :companyDataInSaveCompanyArray="getCompanyDataInSaveCompanyArray"
      :isChartClick="isChartClick"
    />
  </div>
</template>

<script>
import HeaderUnicorn from '@/layouts/HeaderUnicorn';
import Footer from '@/layouts/Footer';
import BottomMenuUnicorn from '@/layouts/BottomMenuUnicorn';
import CompanyModal from '@/components/Modal/CompanyModal';
import { mapGetters } from 'vuex';

export default {
  components: {
    HeaderUnicorn,
    Footer,
    BottomMenuUnicorn,
    CompanyModal
  },
  computed: {
    ...mapGetters('Unicorn', ['isDataLoaded']),
    ...mapGetters('Unicorn', ['getCompanyDataInSaveCompanyArray']),
    ...mapGetters('Unicorn', ['isChartClick'])
  }
};
</script>

<style lang="scss">
@import './assets/scss/main';
</style>
