import store from '@/store';

export default (headers = {}) => {
  return {
    headers: {
      Authorization: `Bearer ${store.getters['user/getToken']}`,
      ...headers
    }
  };
};
