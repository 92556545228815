import Vue from 'vue';
import Vuex from 'vuex';

import Unicorn from './modules/Unicorn';
import user from './modules/user';

Vue.use(Vuex);

// 防止外部code影響內部
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    Unicorn,
    user
  },
  state: {},
  mutations: {},
  actions: {},
  strict: debug
});
