import { setStorage } from '@/store/utils/storage';
import { getToken } from '@/store/utils/user';
import router from '@/router';
import userApi from '@/api/user';
import sha256 from 'crypto-js/sha256';

let token = getToken();

let user = {};

const state = {
  token,
  user
};

const actions = {
  login({ commit }, { Email, LoginKey, callback }) {
    userApi.login(
      { Email, LoginKey },
      res => {
        commit('setToken', res);
        router.push({ name: 'Unicorn' });
      },
      () => {
        callback();
      }
    );
  },
  logout({ commit }) {
    const logoutAction = () => {
      router.push({ name: 'Login' });
      commit('setToken', '');
    };
    logoutAction();
  }
};

const mutations = {
  setToken(state, token) {
    // setStorage('token', token);

    const shaToken = sha256(token).toString();

    // 改成到 存到 cookie
    var expire_days = 1; // 過期日期(天)
    var d = new Date();
    // d.setTime(d.getTime() + (expire_days * 24 * 60 * 60 * 1000));
    d.setTime(d.getTime() + 5 * 60 * 1000);
    var expires = 'expires=' + d.toGMTString();
    document.cookie = 'token=' + encodeURIComponent(shaToken) + '; ' + expires;

    state.token = shaToken;
  },
  updateToken(state, shaToken) {
    // setStorage('token', shaToken);

    // 改成到 存到 cookie
    var expire_days = 1; // 過期日期(天)
    var d = new Date();
    // d.setTime(d.getTime() + (expire_days * 24 * 60 * 60 * 1000));
    d.setTime(d.getTime() + 5 * 60 * 1000);
    var expires = 'expires=' + d.toGMTString();
    document.cookie = 'token=' + encodeURIComponent(shaToken) + '; ' + expires;

    state.token = token;
  }
};

const getters = {
  getToken(state) {
    return state.token;
  },
  isLogin(state) {
    return state.token ? true : false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
