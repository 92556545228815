const state = {
  isDataLoaded: false,
  isChartClick: false,
  companyDataInSaveCompanyArray: [],
  companyDataAllCompanyArray: []
};

const actions = {
  toggleIsDataLoaded({ commit }) {
    commit('setIsDataLoaded', true);
  },
  toggleIsDataLoadedFalse({ commit }) {
    commit('setIsDataLoaded', false);
  },
  toggleIsChartClick({ commit }) {
    commit('setIsChartClick', true);
  },
  toggleIsChartClickFalse({ commit }) {
    commit('setIsChartClick', false);
  }
};

const mutations = {
  setIsDataLoaded(state, isDataLoaded) {
    state.isDataLoaded = isDataLoaded;
  },

  setIsChartClick(state, isChartClick) {
    state.isChartClick = isChartClick;
  },

  setCompanyDataInSaveCompanyArray(state, companyDataInSaveCompanyArray) {
    state.companyDataInSaveCompanyArray = companyDataInSaveCompanyArray;
  },
  setCompanyDataAllCompanyArray(state, companyDataAllCompanyArray) {
    state.companyDataAllCompanyArray = companyDataAllCompanyArray;
  }
};

const getters = {
  isDataLoaded(state) {
    return state.isDataLoaded;
  },

  isChartClick(state) {
    return state.isChartClick;
  },

  getCompanyDataInSaveCompanyArray(state) {
    return state.companyDataInSaveCompanyArray;
  },

  getCompanyDataAllCompanyArray(state) {
    return state.companyDataAllCompanyArray;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
