import { getStorage, setStorage } from './storage';
import _ from 'lodash';

function parseCookie() {
  var cookieObj = {};
  var cookieAry = document.cookie.split(';');
  var cookie;

  for (var i = 0, l = cookieAry.length; i < l; ++i) {
    cookie = cookieAry[i].trim();
    cookie = cookie.split('=');
    cookieObj[cookie[0]] = cookie[1];
  }

  return cookieObj;
}
function getCookieByName(name) {
  var value = _.get(parseCookie(), name, '');
  if (value) {
    value = decodeURIComponent(value);
  }

  return value;
}

export let getToken = () => {
  // let token = getStorage('token');

  // 改成由 cookie 抓取
  let token = getCookieByName('token');

  if (
    !_.isString(token) ||
    _.isEmpty(token) ||
    token !== process.env.VUE_APP_SHATOKEN
  ) {
    token = '';
  }

  return token;
};
