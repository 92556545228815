<template>
  <b-modal
    id="logout-modal"
    modal-class="modal-style logout-modal"
    ref="loginErrorModal"
  >
    <template>
      <h1>
        確定要登出嗎？
      </h1>
      <div class="row mt-4">
        <div class="col-6">
          <button
            class="transparent-btn cancel"
            @click="$bvModal.hide('logout-modal')"
          >
            取消
          </button>
        </div>
        <div class="col-6">
          <button class="custom-btn" @click="handleLogout">登出</button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions('user', ['logout']),
    handleLogout() {
      this.logout();
    }
  }
};
</script>
