<template>
  <b-modal
    id="company-modal"
    modal-class="modal-style company-modal"
    scrollable
    hide-footer
    title="Scrollable Content"
    @hide="handleHide"
  >
    <template v-slot:modal-header class="modal-style-title">
      <button class="close-btn d-block" @click="$bvModal.hide(`company-modal`)">
        <i class="fas fa-times"></i>
      </button>

      <p v-if="isChartClick" class="modal-title">搜尋全部新創團隊</p>
      <p v-if="!isChartClick" class="modal-title">新創團隊篩選結果</p>

      <div class="search-area row">
        <div class="col-8 col-lg-9 right-0">
          <div class="custom-input">
            <i class="fas fa-search"></i>
            <input
              type="text"
              class="custom-text account"
              placeholder="請輸入公司名稱"
              v-model="searchText"
              autocomplete="off"
              @keyup.enter="handleSearch"
            />
            <button class="clear-btn" @click="clearSearch">
              <img src="~@/assets/img/svg/ic_close.svg" alt="工研院圖片" />
            </button>
          </div>
        </div>

        <div class="col-4  col-lg-3">
          <button class="custom-btn" @click="handleSearch">
            搜尋
          </button>
        </div>
      </div>
    </template>
    <div id="scroll-top-position"></div>
    <SingleCompany
      v-for="(oneCompany, index) in filterData"
      :oneCompany="oneCompany"
      :key="index"
    />
    <infinite-loading
      :distance="0"
      @infinite="infiniteHandler"
      ref="loadingComponent"
    >
      <template v-slot:no-results>
        <p>已無更多資訊</p>
      </template>

      <template v-slot:no-more>
        <p>已無更多資訊</p>
      </template>
    </infinite-loading>
  </b-modal>
</template>

<script>
const moment = require('moment');
const VueScrollTo = require('vue-scrollto');

import _ from 'lodash';
import SingleCompany from './SingleCompany';

export default {
  components: {
    SingleCompany
  },
  props: {
    companyDataInSaveCompanyArray: {
      type: Array,
      required: true
    },
    isChartClick: Boolean
  },

  data() {
    return {
      searchText: '',
      searchTextForClick: '',
      showNum: 10,
      options: {
        container: '#company-modal___BV_modal_body_',
        easing: 'ease-in',
        lazy: false,
        offset: -60,
        force: true,
        cancelable: true,
        onStart: function(element) {
          // scrolling started
        },
        onDone: function(element) {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      }
    };
  },

  methods: {
    infiniteHandler($state) {
      if (this.filterDataLength > this.showNum) {
        this.showNum = this.showNum + 10;
        setTimeout(() => {
          $state.loaded();
        }, 2000);
      } else {
        $state.complete();
      }
    },

    clearSearch() {
      this.searchText = '';
      this.handleSearch();
    },

    handleSearch() {
      this.searchTextForClick = this.searchText;
      this.$refs.loadingComponent.stateChanger.reset();
    },

    showNumAdd() {
      let vm = this;
      return new Promise(resolve => {
        const res = 5;
        setTimeout(() => resolve(res), 3000);
      });
    },

    cancelScroll() {
      this.$scrollTo('#scroll-top-position', 500, this.options);
    },

    handleHide() {
      this.searchText = '';
      this.searchTextForClick = '';
      this.showNum = 10;
      this.$refs.loadingComponent.stateChanger.reset();
    }
  },

  watch: {
    searchTextForClick() {
      this.showNum = 10;
      this.cancelScroll();
    }
  },

  computed: {
    dataShow() {
      return this.companyDataInSaveCompanyArray.slice(0, this.showNum);
    },
    filterDataLength() {
      let data = [];
      if (this.searchTextForClick && this.searchTextForClick.trim() !== '') {
        data = this.companyDataInSaveCompanyArray.filter(item => {
          return item.company
            .toLowerCase()
            .includes(this.searchTextForClick.toLowerCase());
        });
        return data.length;
      } else {
        data = this.companyDataInSaveCompanyArray;
        return data.length;
      }
    },
    filterData() {
      let data = [];
      if (this.searchTextForClick && this.searchTextForClick.trim() !== '') {
        let dataTemp = this.companyDataInSaveCompanyArray.filter(item => {
          return item.company
            .toLowerCase()
            .includes(this.searchTextForClick.toLowerCase());
        });
        data = dataTemp.slice(0, this.showNum);
        return data;
      } else {
        data = this.companyDataInSaveCompanyArray;
        return data.slice(0, this.showNum);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
