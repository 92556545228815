import callApi from './base/api';
import authorization from './base/authorization';

export default {
  // 登入
  login({ Email, LoginKey, LoginCategory = 0 }, cb, errCb) {
    const config = {
      data: {
        Email,
        LoginKey,
        LoginCategory
      }
    };
    return callApi({
      url: 'Account/Login',
      method: 'post',
      cb,
      errCb,
      config
    });
  },

  // 一進頁面打API要token
  fetchToken(cb, errCb) {
    return callApi({
      url: 'EventData/GetHardcodeToken',
      method: 'get',
      cb,
      errCb
    });
  }
};
