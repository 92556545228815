<template>
  <div class="the-header-unicorn">
    <header class="header-content">
      <a href="http://startuptaiwan.org/" target="_blank" class="logo-area">
        <h1>
          工研技術研究院
        </h1>
        <!-- 這裏可以載入logo圖片 -->
        <!-- <img
          src="~@/assets/img/logo_svg.svg"
          alt="工研技術研究院"
          class="web-logo"
        /> -->
        <img
          src="~@/assets/img/logo-01.svg"
          alt="工研技術研究院"
          class="web-logo"
        />
      </a>

      <ul class="nav-list">
        <li class="mobile-hide">
          <a href="#" v-scroll-to="'#map-distribute'">團隊募資與地區分佈</a>
        </li>
        <li class="mobile-hide">
          <a href="#" v-scroll-to="'#popular-industry'">
            熱門產業募資概況
          </a>
        </li>
        <li class="mobile-hide">
          <a href="#" v-scroll-to="'#annual-result'">計畫年度成果</a>
        </li>
        <!-- <li class="mobile-hide">
          <a href="#" @click.prevent="$bvModal.show('logout-modal')">登出</a>
        </li> -->
        <li class="d-flex">
          <button
            class="transparent-btn-black filter-btn the-out"
            @click="$bvModal.show('unicorn-filter-modal')"
          >
            <i class="fas fa-sliders-h mr-2"></i>
            <span class="mobile-hide">篩選條件</span>
            <span class="mobile-show">篩選</span>
          </button>

          <button
            class="transparent-btn-black filter-btn the-out"
            @click="handleCompanyModal"
          >
            <!-- <span class="icon--33--3"></span>
            公司 -->
            <i class="fas fa-search"></i>
          </button>
        </li>
        <li class="logout-mobile">
          <a href="#" @click.prevent="$bvModal.show('logout-modal')">
            <img src="~@/assets/img/svg/ic_longout.svg" alt="工研院圖片" />
          </a>
        </li>
      </ul>
    </header>
    <LogoutModal />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LogoutModal from '@/components/Modal/LogoutModal';

export default {
  components: {
    LogoutModal
  },
  computed: {
    ...mapGetters('Unicorn', ['getCompanyDataAllCompanyArray'])
  },
  methods: {
    ...mapActions('Unicorn', ['toggleIsChartClick']),
    ...mapMutations('user', ['setToken']),
    ...mapMutations('Unicorn', ['setCompanyDataInSaveCompanyArray']),

    handleLogout() {
      this.setToken(false);
      this.$router.push({ name: 'Login' });
    },

    handleCompanyModal() {
      this.setCompanyDataInSaveCompanyArray(this.getCompanyDataAllCompanyArray);
      this.toggleIsChartClick();
      this.$bvModal.show(`company-modal`);
    }
  }
};
</script>
