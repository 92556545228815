import Vue from 'vue';
import VueRouter from 'vue-router';
import { setStorage } from '@/store/utils/storage';
import { getToken } from '@/store/utils/user';
import store from '@/store';
import userApi from '@/api/user';

// const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue');
const Unicorn = () =>
  import(/* webpackChunkName: "unicorn" */ '../views/Unicorn/Index');
const Login = () =>
  import(/* webpackChunkName: "login" */ '../views/Login/Index');
// import StadiumChart from '../views/stadium-chart.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   meta: {
  //     title: '新創科技動態監測平台'
  //   }
  // },
  {
    path: '/',
    name: 'Unicorn',
    component: Unicorn,
    meta: {
      title: '預見台灣新創獨角獸',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登入'
    }
  }
  // {
  //   path: '/stadium-chart',
  //   name: '體育場圖表',
  //   component: StadiumChart
  // }
];

// 修改頁面路由重複點擊報錯
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

const router = new VueRouter({
  mode: 'hash',
  routes
});

router.beforeEach((to, from, next) => {
  // 解析網址的query
  function queryString() {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    var query_string = {};
    var query = window.location.search.substring(1);

    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      // If first entry with this name
      if (typeof query_string[pair[0]] === 'undefined') {
        query_string[pair[0]] = pair[1];
        // If second entry with this name
      } else if (typeof query_string[pair[0]] === 'string') {
        var arr = [query_string[pair[0]], pair[1]];
        query_string[pair[0]] = arr;
        // If third or later entry with this name
      } else {
        query_string[pair[0]].push(pair[1]);
      }
    }
    var clean_uri =
      location.protocol + '//' + location.host + location.pathname;
    window.history.replaceState({}, document.title, clean_uri);

    return query_string;
  }

  const tokenFromUrl = queryString().token;

  if (tokenFromUrl) {
    // hash 後儲存到 cookie
    store.commit('user/setToken', tokenFromUrl);
    // location.reload();

    // userApi.fetchToken(
    //   res => {
    //     store.commit('user/setToken', res);
    //   },
    //   () => {
    //     this.$router.push({ name: 'Login' });
    //   }
    // );
  }

  const token = getToken();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token) {
      store.commit('user/updateToken', token);
      if (to.name == 'Login') {
        router.push({ name: 'Unicorn' });
      }
      next();
    } else {
      store.dispatch('user/logout');
    }
  } else {
    next();
  }
});

export default router;
